import React from "react";

const WelcomeSection = () => {

    return(
        <section id="section_welcome" className="webform_area">

            <div className="container">
                <div className="row">
                    <div className="col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
                    </div>
                    <div className="col-lg-6">
                        <h3>Mobile Apps</h3>
                        <p>
                            Laden Sie sich die mobilen Apps aus dem jeweiligen App-Store auf Ihr Gerät. 
                        </p>
                        <p>
                            <span>
                                <a rel="noopener noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=at.joobster.micro">
                                    <img src={require("../img/android-app-download.png")} alt="joobster micro download"/>
                                </a>
                            </span>
                            <span>
                                <a rel="noopener noreferrer" target="_blank" href="https://apps.apple.com/app/joobster-micro/id1464189059">
                                    <img src={require("../img/ios-app-download.png")} alt="joobster micro download"/>
                                </a>
                            </span>
                        </p>
                    </div>
                    <div className="col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
                    </div>
                    <div className="col-lg-6">
                        <h3>Web App</h3>
                        <p>
                            Für den schnellen und flexiblen Zugriff verwenden Sie die browserbasierte App. 
                        </p>
                        <p>
                            <span>
                                    <a rel="noopener noreferrer" target="_blank" href="https://micro.joobster.at">
                                        <img src={require("../img/web-app.png")} alt="joobster micro web app"/>
                                    </a>
                            </span>
                        </p>
                    </div>
                    <div className="col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
                    </div>
                    <div className="col-lg-6">
                        <h3>Verwaltung</h3>
                        <p>
                            Sie haben mit Ihrer Registrierung Zugriff auf das Verwaltungstool für die 
                            joobster micro App. In der Verwaltungsoberfläche können Sie die Benutzer 
                            verwalten und Inhalte erstellen. 
                        </p>
                        <p>
                            <span>
                                    <a rel="noopener noreferrer" target="_blank" href="https://admin.joobster.at">
                                        <img src={require("../img/admin-app.png")} alt="joobsteradmin app"/>
                                    </a>
                            </span>
                        </p>
                    </div>
                    <div className="col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
                    </div>
                </div>
            </div>
        </section>
    )
}
export default WelcomeSection;
