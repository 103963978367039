import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import ScrollToTopRoute from "./ScrollToTopRoute";
import HomeChat from "./Pages/Home-chat";
import Check from "./Pages/Check";
import Signup from "./Pages/Signup";
import Impress from "./Pages/Impress";
import Privacy from "./Pages/Privacy";
import Welcome from "./Pages/Welcome";
import NotFound from "./Pages/404";

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={HomeChat} />
          <ScrollToTopRoute path="/check" component={Check} />
          <ScrollToTopRoute path="/anmeldung" component={Signup} />
          <ScrollToTopRoute path="/willkommen" component={Welcome} />
          <ScrollToTopRoute path="/impressum" component={Impress} />
          <ScrollToTopRoute path="/impressum" component={Impress} />
          <ScrollToTopRoute path="/datenschutz" component={Privacy} />
          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
