import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Sticky from 'react-stickynode';

class CustomNavbar extends Component {
    render() {
        var {mClass, cClass, slogo, hideNavbar} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/logo2.png")} alt=""/>
                            <img src={require("../img/logo.png")} alt="logo"/>
                        </Link>

                        { !hideNavbar && (
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto`}>
                                <li className="nav-item">
                                    <a href="#section_home" className="nav-link" role="button">
                                        Home
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a href="#section_customer" className="nav-link" role="button">
                                        Kunden
                                    </a>
                                </li>
                                
                                <li className="nav-item">
                                    <a href="#section_service" className="nav-link" role="button">
                                        Service
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a href="#pricing_customer" className="nav-link" role="button">
                                        Preise
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a href="#section_contact" className="nav-link" role="button">
                                        Kontakt
                                    </a>
                                </li>
                            </ul>
                        </div>
                        )}
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;