import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import CheckSection from '../components/CheckSection';
import FooterErp from '../components/Footer/FooterErp';

const Check = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto" hideNavbar={true}/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Domain Check" Pdescription=""/>
            <CheckSection />
            <FooterErp fClass="event_footer_area" />
        </div>
    )
}
export default Check;