import React from "react";

const CoreFeatures = () => {
  return (
    <section id="section_core" className="chat_core_features_area sec_pad">
      <div className="container">

        <div className="hosting_title chat_title text-center">
          <h2 className="wow fadeInUp" data-wow-delay="0.2s">
            Warum sind wir besser als unsere Konkurrenz?
          </h2>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12">
          <div className="chat_features_item wow fadeInUp" data-wow-delay="0.2s">
            <p className="chat_body">
              Wir haben unsere Lösungen zu Ende gedacht! Mit joobster erhalten Sie sämtliche 
              Tools um noch schneller und einfacher Inhalte Ihren Mitarbeitern vermitteln zu können.
            </p>
          </div>
          </div>
        </div>

        <div className="row"> 
          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp" data-wow-delay="0.2s">
              <div className="round">
                <div className="round_circle two"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/triangle.png")}
                  alt=""
                />
                <img src={require('../../img/home-chat/mobile.png')} alt="" />
              </div>
              <h4>Videos erstellen</h4>
              <p>
                Mit joobster erhalten Sie eine vollständige Produktionssuite
                um schnell und einfach Ihre Ideen in Videos umsetzen zu können. 
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp">
              <div className="round">
                <div className="round_circle"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/pluse.png")}
                  alt=""
                />
                <img src={require("../../img/home-chat/chat.png")} alt="" />
              </div>
              
              <h4>Schulungen erstellen</h4>
              <p>
                Erstellen Sie Schlungsinhalte aus Videos, PDF, Powerpoint, Excel und Word. 
                Und definieren Sie zu welchen Zeitpunkten die Schulungen automatisiert gestartet werden.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp" data-wow-delay="0.4s">
              <div className="round">
                <div className="round_circle three"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/box.png")}
                  alt=""
                />
                <img src={require('../../img/home-chat/book.png')} alt="" />
              </div>
              
              <h4>Zertifikate verwalten</h4>
              <p>
                Ab sofort können Sie zu 100% belegen, dass Ihre Mitarbeiter erfolgreich geschult 
                und unterwiesen worden sind. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CoreFeatures;
