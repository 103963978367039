import React, { useState, useEffect }  from "react";
import axios from 'axios';

import config from '../config';

const SignupSection = () => {

    const [regState, setRegState] = useState("checkDomain");
    const [error, setError] = useState([]);
    const [signup, setSignup] = useState({});
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const hash = queryParams.get("hash")

        if (regState === "checkDomain") {
            axios.post(config.baseEndPoint + '/external/checkhash/'+hash, {})
            .then(function (resp) {
                const data = resp.data;
                if (data.exists) {
                    update("hash", hash);
                    setRegState("signUp");
                } else {
                    setRegState("checkFail");
                }
            })
            .catch(function () {
                setError(["Es gibt ein Problem, die Nachricht konnte nicht gesendet werden! Bitte probieren Sie es später nochmal, oder kontaktieren Sie uns unter hello@joobster.at"]);
                setRegState("checkFail")
            });
        }
    });

    const signUpButton = () => {

        // don't allow double submit
        if (submitted) {
            return;
        }

        if (!validated()) {
            return;
        }

        setSubmitted(true);
        axios.post(config.baseEndPoint + '/external/microappsignup', { ...signup })
        .then(function (resp) {
            const data = resp.data;
            if (data.signup) {
                window.location.replace('/willkommen');
            } else {
                setRegState("signUpFail")
            }
        })
        .catch(function () {
            setError(["Es gibt ein Problem, die Nachricht konnte nicht gesendet werden! Bitte probieren Sie es später nochmal, oder kontaktieren Sie uns unter hello@joobster.at"]);
        });
    };

    const update = (field, value) => {
        const update = signup;
        update[field] = value.trim();
        setSignup(update);
    }

    const validated = () => {
        let errors = [];
        if ((signup.name || []).length === 0) {
            errors.push("Firmenname ist erforderlich");
        }
        if ((signup.website || []).length === 0) {
            errors.push("Website ist erforderlich");
        }
        if ((signup.contactName || []).length < 4) {
            errors.push("Kontakt ist erforderlich");
        }
        if ((signup.userName || []).length < 4) {
            errors.push("Username ist erforderlich, min. 4 Zeichen");
        }
        if ((signup.userName || []).indexOf(" ") > -1) {
            errors.push("Username darf keine Leerzeichen enthalten");
        }
        if (errors.length > 0) {
            setError(errors);
            return false;
        }
        return true;
    };

    const getErrors = () => {
        return (<ul className="signup_page_errors">{ errorList() }</ul>);
    };

    const errorList = () => {
        return error.map((err, idx) => {
            const key = "err" + idx;
            return (<li key={key}>{err}</li>);
        });
    };

    return(
        <section className="signup_page">
            <div className="container">
                <div className="text-center">
                
                    { regState === "checkDomain" && (
                        <div className="row">
                            <div className="col-lg-12 wow fadeInUp c_tag" data-wow-delay="0.4s">
                                Registrierungscode wird geprüft!
                            </div>
                        </div>
                    )}

                    { regState === "signUp" && (
                        <div className="row">

                            <div className="col-lg-12 wow fadeInUp c_tag" data-wow-delay="0.4s">
                                Einfach Daten eingeben und abheben. Sie erhalten alle Information die Sie 
                                für den Start benötigen per Email zugestellt!
                            </div>
                               
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.4s">

                                { error.length > 0 && (
                                    <div className="form-group text_box chat_banner_email_validator">  
                                        { getErrors() }
                                    </div>
                                )}

                                <div className="form-group text_box">
                                    <input type="text" name="name" id="name" placeholder="Firmenname, z.B.: joobster gmbh" onChange={event => update("name", event.target.value )}/>
                                </div>

                                <div className="form-group text_box">
                                    <input type="text" name="website" id="web" placeholder="Website, z.B.: http://joobster.at" onChange={event => update("website", event.target.value )}/>
                                </div>

                                <div className="form-group text_box">
                                    <input type="text" name="contactName" id="contact" placeholder="Anprechpartner, z.B.: Max Müller" onChange={event => update("contactName", event.target.value )}/>
                                </div>

                                <div className="form-group text_box">
                                    <input type="text" name="userName" id="userName" placeholder="Benutzername (min 4 Zeichen, keine Leerzeichen)" onChange={event => update("userName", event.target.value )}/>
                                </div>

                                <div className="form-group text_box">
                                    <p>
                                        Verwenden Sie für den Login das Passwort: <strong>demo1234</strong>
                                    </p>
                                </div>

                                <div className="form-group">
                                    <a className="chat_btn btn_hover" onClick={signUpButton}>
                                        Jetzt Anmelden
                                    </a>
                                </div>
                            </div>

                            <div className="col-lg-6 text-left fadeInUp" data-wow-delay="0.4s">
                                <img className="signup-image" src={require("../img/login_img2.png")} alt="" />
                            </div>

                        </div>
                    )}

                    { regState === "checkFail" && (
                        <div className="row">
                            <div className="col-lg-12 wow fadeInUp c_tag" data-wow-delay="0.4s">
                                Der Code ist leider nicht mehr gültig! Bitte registrieren Sie sich erneut oder kontaktieren Sie das joobster team.
                            </div>
                        </div>
                    )}

                    { regState === "signUpSuccess" && (
                        <div className="row">
                            <div className="col-lg-12 wow fadeInUp c_tag" data-wow-delay="0.4s">
                                Vielen Dank für Ihre Interesse! Sie können sich auf admin.joobster.at mit Ihrer Email-Adresse und Passwort einloggen.
                            </div>
                        </div>
                    )}

                    { regState === "signUpFail" && (
                        <div className="row">
                            <div className="col-lg-12 wow fadeInUp c_tag" data-wow-delay="0.4s">
                                Die Domain ist bereits registriert! Wir prüfen den Eintrag und melden uns per Email.
                            </div>
                        </div>
                    )}
                    
                </div>
            </div>
        </section>
    )
}

export default SignupSection;