import React from 'react';

const CustomersSection =()=>{
    return(
        <section id="section_customer2" className="security_integration_area chat_integration_area">
            <div className="container">
                
                <div className="chat_title text-center">
                    <h2 className="wow fadeInUp"><span>50+ Kunden</span> setzen bereits auf smarte Lösungen von joobster!</h2>
                    <p>
                        joobster ist österreichs führender Anbieter von Enterprise-Videolösungen für Unternehmen.
                    </p>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">

                    <div className="col-lg-2 col-md-4 col-sm-6 s_integration_item">
                        <img src={require("../img/new-home/rma-logo.png")} alt=""/>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 s_integration_item">
                        <img src={require("../img/new-home/ams-logo.png")} alt=""/>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 s_integration_item">
                        <img src={require("../img/new-home/gls-logo.png")} alt=""/>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 s_integration_item">
                        <img src={require("../img/new-home/axians-logo.png")} alt=""/>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 s_integration_item">
                        <img src={require("../img/new-home/remax-logo.png")} alt=""/>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 s_integration_item">
                        <img src={require("../img/new-home/gf+-logo.png")} alt=""/>
                    </div>

                </div>
            </div>
        </section>
    )
}
export default CustomersSection;
