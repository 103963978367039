import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import ChatBanner from "../components/Banner/ChatBanner";
import CoreFeatures from "../components/Features/CoreFeatures";
import ChatFeatures1 from "../components/Features/ChatFeatures1";
import ChatFeatures2 from "../components/Features/ChatFeatures2";
import ChatFeatures3 from "../components/Features/ChatFeatures3";
import CustomersSection from "../components/CustomersSection";
import ChatClients from "../components/ChatClients";
import PricingSection from "../components/PricingSection";
import CustomerSection from "../components/CustomerSection";
import FooterErp from "../components/Footer/FooterErp";

const homechat = () => {
  return (
    <div className="body_wrapper">
      <CustomNavbar nClass="m-auto" hbtnClass="new_btn" />
      <ChatBanner />
      <CustomerSection />
      <CustomersSection />
     
      <ChatFeatures1 rowClass='flex-row-reverse' pClass="pr_70" col1="col-lg-6 offset-lg-1" col2="col-lg-5" img1="dot.png" />
      <ChatFeatures2 pClass="pl_70" col1="col-lg-6" col2="col-lg-5 offset-lg-1" img1="dot.png" />
      <ChatFeatures3 rowClass='flex-row-reverse' pClass="pr_70" col1="col-lg-6 offset-lg-1" col2="col-lg-5" img1="dot.png" />

      <CoreFeatures />
      <PricingSection />
      <ChatClients/>
      <FooterErp fClass="event_footer_area" />
    </div>
  );
};
export default homechat;
