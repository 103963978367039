import React from 'react';
import YouTube from 'react-youtube';

const CustomerSection =()=>{
    const opts = {
        width: '590'
    };
    return(
        <section id="section_customer" className="security_integration_area chat_integration_area">
            <div className="container">
                <div className="chat_title text-center">
                    <h2 className="wow fadeInUp"><strong>SERVICE</strong> wird bei uns groß geschrieben!</h2>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <YouTube videoId="4-asVqpOQd8" opts={opts} />
                    </div>
                    <div className="col-lg-6">
                        <YouTube videoId="ra5hH1E0-lg" opts={opts} />
                    </div>
                </div>
                <div className="row">
                    <strong>Patric Grafeneder</strong>, Sicherheitsfachkraft bei Hasenöhrl GmbH
                </div>
            </div>
        </section>
    )
};
export default CustomerSection;
