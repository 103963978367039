import React from 'react';

const PricingSection =()=>{
    return(
        <section id="pricing_customer" className="security_integration_area chat_integration_area">
            <div className="container">
                <div className="chat_title text-center">
                    <h2 className="wow fadeInUp"><span>Preise</span></h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 text-center pricing_col1">
                        <h3>MA100</h3>
                        <p className="subtitle"><small>bis zu 100 MA</small></p>
                        <p className="info text-left"><strong>MA100</strong> ist für bis zu 100 Mitarbeiter, 
                        sowohl als APP als auch im Browser zugänglich.
                        </p>
                        <p className="price">
                            <strong>€390,-</strong>
                        </p>
                        <p className="price_sub">
                            pro Monat
                        </p>
                    </div>
                    <div className="col-lg-4 text-center pricing_col2">
                        <h3>MA500</h3>
                        <p className="subtitle"><small>bis zu 500 MA</small></p>
                        <p className="info text-left">
                            <strong>MA500</strong> ist für bis zu 500 Mitarbeiter, 
                            sowohl als APP als auch im Browser zugänglich.
                        </p>
                        <p className="info text-left">
                            <small><strong>Genereller Zusatzverkauf</strong> von Schulungsinhalten kann über joobster action
                            in Eigenregie, oder durch joobster als Dienstleistung vollumfänglich angeboten werden.</small>
                        </p>
                        <p className="price"><strong>€590,-</strong></p>
                        <p className="price_sub">
                            pro Monat
                        </p>
                    </div>
                    <div className="col-lg-4 text-center pricing_col3">
                        <h3>MAPLUS</h3>
                        <p className="subtitle"><small>bis zu 1000 MA</small></p>
                        <p className="info text-left">
                            <strong>MAPLUS</strong> ist für bis zu 1000 Mitarbeiter, 
                            sowohl als APP als auch im Browser zugänglich.
                        </p>
                        <p className="price"><strong>€990,-</strong></p>
                        <p className="price_sub">
                            pro Monat
                        </p>
                    </div>
                </div>
                <p><strong>(ab 1000 Mitarbeiter (MA) auf Anfrage)</strong></p>
            </div>
        </section>
    )
};
export default PricingSection;