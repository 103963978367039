import React, { Component } from "react";

class ChatFeatures2 extends Component {
  render() {
    let {
      rowClass,
      col1,
      col2,
      pClass
    } = this.props;
    return (
      <section className="chat_features_area">
        <div className="container">
          <div className={`row align-items-center ${rowClass}`}>
            <div className={col1}>
                <img
                  className="dot_bg"
                  src={require("../../img/home-chat/questions.png")}
                  alt="" />
            </div>
            <div className={col2}>
              <div className={`chat_features_content ${pClass}`}>
                <img src={require("../../img/home-chat/chat_icon.png")} alt="" />
                <h2>
                  <span>2) Fragen</span> beantworten
                </h2>
                <p>
                    Als <strong>mobile APP</strong> (iOS und Android) und als <strong>Web-Browserversion</strong> verfügbar. 
                </p>
                <p>
                    Die Trainings werden in den von Ihnen definierten Intevallen <strong>automatisch</strong> wiederholt. 
                </p>
                <p>
                    Ab jetzt sind Ihre Mitarbeiter immer gesetzeskonform geschult. Und das, mit minimalem Aufwand!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ChatFeatures2;
