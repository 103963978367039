import React, { useState }  from "react";
import validator from 'validator';
import axios from 'axios';
import YouTube from 'react-youtube';

import config from '../config';

const CheckSection = () => {

    const [regState, setRegState] = useState("checkEmail");
    const [error, setError] = useState([]);
    const [email, setEmail] = useState("");

    const opts = {
        width: '590'
    };

    const checkEmailButton = () => {
        if (validator.isEmail(email.trim())) {
            axios.post(config.baseEndPoint + '/external/microappcheck', { email })
            .then(function (resp) {
                const data = resp.data;
                if (data.signup) {
                    setRegState("signUp")
                } else {
                    setRegState("domainExists")
                }
            })
            .catch(function () {
              setError(["Es gibt ein Problem, die Nachricht konnte nicht gesendet werden! Bitte probieren Sie es später nochmal, oder kontaktieren Sie uns unter hello@joobster.at"]);
            });
        } else {
            setError(["Bitte überprüfen Sie die eingegebene Email-Adresse!"]);
        }
    }

    return(
        <section className="check_page">
            <div className="container">
                <div className="text-center">
                    <p className="check_page_header">
                        Geben Sie bitte Ihre Firmen-Emailadresse ein! 
                        Wir überprüfen, ob die Domain in unserem System noch für eine Registrierung verfügbar ist.
                    </p>

                    { regState === "checkEmail" && (
                        <div className="row">
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.4s">

                                { error.length > 0 && (
                                    <div className="form-group text_box">
                                        <p className="chat_banner_email_validator">
                                            { error[0] }
                                        </p>
                                    </div>
                                )}

                                <p className="c_tag">
                                    Verwenden Sie bitte eine Email-Adresse mit Ihrer Firmendomain.
                                </p>
                            
                                <div className="text_box">
                                    <input type="text" name="email" id="email" placeholder="Email-Adresse (z.B.: hello@joobster.at)"  onChange={event => setEmail(event.target.value)} />
                                </div>

                                <div className="form-group">
                                    <a className="chat_btn btn_hover" onClick={checkEmailButton}>
                                        Domain checken
                                    </a>
                                </div>

                            </div>

                            <div className="col-lg-6 text-left fadeInUp" data-wow-delay="0.4s">
                                <YouTube videoId="nRkqBer4Ytw" opts={opts} />
                            </div>
                        </div>
                    )}

                    { regState === "signUp" && (
                        <div className="row">
                            <div className="col-lg-6 wow fadeInUp c_tag" data-wow-delay="0.4s">
                                Wir haben Ihnen eine Email gesendet! Bitte bestätigen Sie die Email-Adresse um fortzufahren.
                            </div>
                            <div className="col-lg-6 text-left fadeInUp" data-wow-delay="0.4s">
                                <YouTube videoId="nRkqBer4Ytw" opts={opts} />
                            </div>
                        </div>
                    )}

                    { regState === "domainExists" && (
                        <div className="row">
                            <div className="col-lg-12 wow fadeInUp c_tag" data-wow-delay="0.4s">
                                Die Domain ist bereits registriert! Wir prüfen den Eintrag und melden uns per Email.
                            </div>
                        </div>
                    )}
                    
                </div>
            </div>
        </section>
    );
}

export default CheckSection;