import React from "react";

const ChatBanner = () => {

  return (
    <section id="section_home" className="chat_banner_area">
      <img
        className="p_absoulte cloud"
        data-parallax='{"x": 0, "y": 150}'
        src={require('../../img/home-chat/cloud.png')}
        alt=""
      />
      <img
        className="p_absoulte left wow fadeInLeft"
        data-wow-delay="0.2s"
        src={require('../../img/home-chat/left_leaf.png')} 
        alt=""
      />
      <img
        className="p_absoulte right wow fadeInRight"
        data-wow-delay="0.3s"
        src={require('../../img/home-chat/right_leaf.png')}
        alt=""
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="chat_banner_content">
              <div className="c_tag wow fadeInUp">
                <img src={require("../../img/home-chat/live_chat.png")} alt="Sicherheitsunterweisungen und Schulungen" />
                Sicherheitsunterweisungen und Schulungen
              </div>
              <h2 className="wow fadeInUp" data-wow-delay="0.2s">
                <small>Die Nummer 1 für</small><br/> <strong>Digitale Arbeitssicherheit</strong>
              </h2>

              <p> 
              Gemäß <strong>ArbeitnehmerInnenschutzgesetz (ASchG)</strong> sind Arbeitgeber dazu verpflichtet für eine
              ausreichende Unterweisung der Arbeitnehmer über Sicherheit und Gesundheitsschutz zu sorgen!
              </p>

              <p className="c_tag text-center"> 
              Stellen Sie sicher, dass Ihre Mitarbeiter perfekt geschult sind!
              </p>

              <p>
              joobster micro unterstützt Sie <strong>schnell</strong>, <strong>einfach</strong> und <strong>kostengünstig</strong>! 
              </p>

              <div className="text-center pr_30 wow fadeInUp" data-wow-delay="0.4s">

                <div className="text-left chat_banner_arguments">
                  <img src={require("../../img/home-chat/ok.png")} alt="" />
                  <strong className="c_tag">In weniger als 10 Min. einsatzbereit</strong>
                </div>

                <div className="text-left chat_banner_arguments">
                  <img src={require("../../img/home-chat/ok.png")} alt="" />
                  <strong className="c_tag">Alle Funktionen zu 100% nutzen</strong>
                </div>

                <div className="text-left chat_banner_arguments">
                  <img src={require("../../img/home-chat/ok.png")} alt="" />
                  <strong className="c_tag">Zertifikate sofort erstellen</strong>
                </div>
                
                <br/>
                <a href="/check" className="chat_btn btn_hover">
                  Jetzt kostenlos testen
                </a>
                
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="chat_img">
              <img
                className="wow fadeInUp"
                data-wow-delay="0.2s"
                src={require('../../img/home-chat/Messages.png')}
                alt="chat-img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatBanner;
