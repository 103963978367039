import React, { Component } from "react";

class ChatFeatures1 extends Component {
  render() {
    let {
      rowClass,
      col1,
      col2,
      pClass
    } = this.props;
    return (
      <section id="section_service" className="chat_features_area">
        <div className="container">
          <div className="chat_title text-center">
            <h2 className="wow fadeInUp">In <span>3 Schritten</span> zum fertigen Zertifikat</h2>
          </div>
          <div className={`row align-items-center ${rowClass}`}>
            <div className={col1}>
                <img
                  className="dot_bg"
                  src={require("../../img/home-chat/video.png")}
                  alt=""
                />
            </div>
            <div className={col2}>
              <div className={`chat_features_content ${pClass}`}>
                <img src={require("../../img/home-chat/video_icon.png")} alt="" />
                <h2>
                  <span>1) Content</span> erstellen
                </h2>
                <p>
                    In weniger als 15 Minuten einsatzbereit!
                </p>
                <p>
                    Einfacher geht's nicht: <strong>PDF</strong>, <strong>Powerpoint</strong>, <strong>Excel</strong> oder <strong>Word</strong> hochladen, 
                    mit Teilnehmern verknüpfen und loslegen.
                </p>
                <p>
                    Videos mit der joobster Enterprise-Suite erstellen und direkt 
                    einbinden. Damit Sie Ihre Inhalte zum Leben erwecken und
                    komplexe Prozesse einfach erklären können. 
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ChatFeatures1;
